@import url('https://cdn.jsdelivr.net/gh/tonsky/FiraCode@4/distr/fira_code.css');

@import '~bootswatch/dist/superhero/variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootswatch/dist/superhero/bootswatch';

@import './assets/scss/icons.scss';

h4 {
  margin-top: 40px;
}

p {
  margin-left: 10px;
}

i.fas {
  margin-right: 5px;
}

.card {
  margin-bottom: 30px;

  &:first-of-type {
    margin-top: 30px;
  }
}

.code-error {
  white-space: pre;
  margin-bottom: 0;
}

.compilation-error .message-body {
  white-space: pre-wrap;
  border-radius: 0;
  border-left-width: 6px;
}

.sql-editor {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 25px;
  border: 1px solid #000;
}

.editor-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .results-table {
    flex: 5;
    border: none;
    border-left: 1px solid #000;
  }
}
.editor-left {
  flex: 3;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .react-codemirror2 {
    height: 100%;
  }

  .CodeMirror {
    height: 100%;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Fira Code', monospace;
  }
}

.sticky-nav {
  padding-top: 30px;
  a.active {
    font-weight: bold;
  }
}
